<script>
const fileDownloadUrl = process.env.VUE_APP_URL + "/api/Files/";

import { authHeader } from "@/helpers/authservice/auth-header";
import { formatDate } from "devextreme/localization";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "DebtorFilesComponent",
  components: {},
  data() {
    return {
      fileDownloadUrl,
      headers: authHeader(),
    };
  },
  computed: {
    ...mapGetters({
      files: "debtorCardInfo/writsFiles",
    }),
  },
  methods: {
    download(item) {
      axios.get(fileDownloadUrl + item.id, { responseType: "arraybuffer" }).then(function (response) {
        var headers = response.headers;
        var blob = new Blob([response.data], { type: headers["content-type"] });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.fileName;
        link.click();
      });
    },

    fdt(date) {
      if (!date) return "";
      return formatDate(new Date(date), "shortDateShortTime");
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-xl-12 d-flex align-items-center">
      <h6>Файлы приложенные к искам:</h6>
    </div>
    <div class="col-xl-12 d-flex align-items-center">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th width="250px">Дата и время добавления</th>
            <th width="250px">Пользователь, приложивший файл</th>
            <th>Файл</th>
            <th>Номер дела</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in files" :key="index">
            <td style="width: 80px"><i class="bx bxs-file fs-24 text-muted"></i></td>
            <td>{{ fdt(item.created) }}</td>
            <td>{{ item.createdBy }}</td>
            <td>
              <a href="#/" @click="download(item)"> {{ item.fileName }}</a>
            </td>
            <td>{{ item.executionDocNo }}</td>
            <td>
              <a href="#" v-if="item.id != 0" @click.prevent="download(item)">Скачать</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
