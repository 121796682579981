<script>
import DebtorFiles from "./_debtorFiles.vue";
import WritsFiles from "./_writsFiles.vue";

export default {
  name: "DebtorFilesComponent",
  components: {
    DebtorFiles,
    WritsFiles,
  },
};
</script>

<template>
  <div>
    <DebtorFiles />
    <WritsFiles />
  </div>
</template>
