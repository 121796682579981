<script>
const fileUploadUrl = process.env.VUE_APP_URL + "/api/Files/UploadChunk";
const fileDownloadUrl = process.env.VUE_APP_URL + "/api/Files/";

import { DxFileUploader } from "devextreme-vue/file-uploader";
import { authHeader } from "@/helpers/authservice/auth-header";
import { confirm } from "devextreme/ui/dialog";
import { mapGetters, mapActions } from "vuex";
import { formatDate } from "devextreme/localization";
import axios from "axios";

export default {
  name: "DebtorFilesComponent",
  components: {
    DxFileUploader,
  },
  data() {
    return {
      fileUploadUrl,
      fileDownloadUrl,
      formatDate,
      headers: authHeader(),
    };
  },
  computed: {
    ...mapGetters({
      files: "debtorCardInfo/debtorFiles",
    }),
  },
  methods: {
    ...mapActions({
      addFile: "debtorCardInfo/addFile",
      removeFile: "debtorCardInfo/removeFile",
    }),
    filesUploaded(e) {
      var obj = JSON.parse(e.request.responseText);
      //   this.files.push({ id: 0, tempFileName: obj.tempFileName, fileName: e.file.name });
      const file = { id: 0, tempFileName: obj.tempFileName, fileName: e.file.name };
      this.addFile(file);
      e.component.reset();
    },
    download(item) {
      axios.get(fileDownloadUrl + item.id, { responseType: "arraybuffer" }).then(function (response) {
        var headers = response.headers;
        var blob = new Blob([response.data], { type: headers["content-type"] });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.fileName;
        link.click();
      });
    },
    deleteFile(item) {
      let result = confirm(
        "<i class='dx-icon ri-delete-bin-5-line align-middle fs-22 me-2'></i><span class='fs-5 align-middle'>Вы уверены?</span>",
        "Удаление файлов"
      );
      result.then((dialogResult) => {
        if (dialogResult) {
          this.removeFile(item.id);
        }
      });
    },

    fdt(date) {
      return formatDate(new Date(date), "shortDateShortTime");
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-xl-12 d-flex flex-column">
      <h6>Файлы приложенные к должнику:</h6>
      <DxFileUploader
        name="file"
        :multiple="false"
        :chunk-size="200000"
        :upload-url="fileUploadUrl"
        :uploadHeaders="headers"
        @upload-started="() => (chunks = [])"
        @uploaded="filesUploaded"
        :max-file-size="209715200"
      />
    </div>
    <div class="col-xl-12 d-flex align-items-center">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th width="250px">Дата и время добавления</th>
            <th width="250px">Пользователь, приложивший файл</th>
            <th>Файл</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in files" :key="index">
            <td style="width: 80px"><i class="bx bxs-file fs-24 text-muted"></i></td>
            <td>{{ fdt(item.created) }}</td>
            <td>{{ item.createdBy }}</td>
            <td>
              <a href="#/" @click="download(item)"> {{ item.fileName }}</a>
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-between">
                <a href="#" v-if="item.id != 0" @click.prevent="download(item)">Скачать</a>
                <a href="#" @click.prevent="deleteFile(item, index)">Удалить</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
