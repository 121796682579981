<template>
  <Layout>
    <transition name="fade">
      <PageHeader :title="title" :items="items" v-if="title">
        <div class="d-flex gap-2">
          <DebtSummaryText :actualDate="debtsData.actualDebtsGrouped.actualDate" :actualDebts="f(debtsData.actualDebtsGrouped.debtsAll)" />
          <b-button variant="success" @click="startDownloadSummaryReport">
            <span class="align-middle">Справка</span>
          </b-button>
        </div>
      </PageHeader>
    </transition>
    <DxLoadPanel :position="{ of: '#mainCard' }" :visible="isLoading" shading-color="rgba(0,0,0,0.4)" />
    <div class="card" id="mainCard">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <b-tabs content-class="mt-3" @activate-tab="tabActivated" v-model="tabIndex" fill>
              <b-tab active>
                <template #title>
                  <div class="d-flex h-100">
                    <span class="m-auto">Долги и платежи</span>
                  </div>
                </template>
                <DebtsAndPayments ref="tab0" :debtorId="id" />
              </b-tab>
              <b-tab title="Жители">
                <template #title>
                  <div class="d-flex h-100">
                    <div class="m-auto">
                      <div>Жители</div>
                      <div>
                        всего - <span>{{ inhabitantsCount }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <Inhabitants ref="tab1" :debtorId="id" :debtorData="debtorData" />
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="d-flex h-100">
                    <div class="m-auto">
                      <div>Соглашения</div>
                      <div>
                        всего - <span> {{ agreementsCount }} </span>
                      </div>
                    </div>
                  </div>
                </template>
                <Agreements ref="tab2" :debtorId="id" />
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="d-flex h-100">
                    <div class="m-auto">
                      <div>Иски и ИП</div>
                      <div>
                        всего - <span>{{ writsCount }}</span> архив - <span>{{ writsArchivedCount }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <Writs ref="tab3" :debtorId="id" />
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="d-flex h-100">
                    <div class="m-auto">
                      <div>Журнал работы</div>
                      <div>записей о перс.работе - {{ debtorWorkLogCount }}</div>
                    </div>
                  </div>
                </template>
                <WorkWithDebtor ref="tab4" :debtorId="id" />
              </b-tab>
              <b-tab>
                <template #title>
                  <div class="d-flex h-100">
                    <div class="m-auto">
                      <div>Файлы</div>
                      <div>всего - {{ debtorFilesCount }}</div>
                    </div>
                  </div>
                </template>
                <Files ref="tab4" :debtorId="id" />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { ref, provide } from "vue";
import { mapGetters, mapActions } from "vuex";
import { formatNumber } from "devextreme/localization";
import { useStorage } from "@vueuse/core";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import DebtsAndPayments from "@/components/debtor-card/debts-and-payments";
import Inhabitants from "@/components/debtor-card/inhabitants";
import Writs from "@/components/debtor-card/writs";
import Agreements from "@/components/debtor-card/agreements";
import DebtSummaryText from "../../components/debtor-card/debtSummaryText.vue";
import WorkWithDebtor from "../../components/debtor-card/workWithDebtor.vue";
import Files from "../../components/debtor-card/files.vue";

export default {
  components: {
    Layout,
    PageHeader,
    DebtsAndPayments,
    Writs,
    Inhabitants,
    Agreements,
    DebtSummaryText,
    WorkWithDebtor,
    Files,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    var isLoading = ref(true);
    provide("isLoading", isLoading);

    var actualDebts = ref(0);
    function setActualDebts(debts) {
      actualDebts.value = debts;
    }
    provide("actualDebts", actualDebts);
    provide("setActualDebts", setActualDebts);

    var agreementsCount = ref(0);
    function setAgreementsCount(count) {
      agreementsCount.value = count;
    }
    provide("setAgreementsCount", setAgreementsCount);

    var writsCount = ref(0);
    var inhabitantsCount = ref(0);

    function setWritsCount(count) {
      writsCount.value = count;
    }
    function setInhabitantsCount(count) {
      inhabitantsCount.value = count;
    }
    provide("setWritsCount", setWritsCount);
    provide("setInhabitantsCount", setInhabitantsCount);

    const tabIndex = useStorage("debtor-card-active-tab", 0);

    return { isLoading, setActualDebts, actualDebts, agreementsCount, writsCount, inhabitantsCount, tabIndex };
  },
  mounted() {
    this.startLoadCard();
  },
  data() {
    return {
      items: [
        { text: "Главная", href: "/" },
        { text: "Должники", href: "/debtors" },
        { text: "Карточка должника", active: true },
      ],
    };
  },

  computed: {
    ...mapGetters({
      title: "debtorCardInfo/title",
      writsArchivedCount: "debtorCardInfo/writsArchivedCount",
      debtorWorkLogCount: "debtorCardInfo/debtorWorkLogCount",
      debtorFilesCount: "debtorCardInfo/debtorFilesCount",
      address: "debtorCardInfo/address",
      error: "debtorCardInfo/error",
      debtorData: "debtorCardInfo/debtorData",
      debtsData: "debtorCardDebtsAndPaymentsData/debtsData",
    }),

    ...mapGetters({
      error: "debtorCardDebtsAndPaymentsData/error",
    }),
  },

  methods: {
    ...mapActions({
      loadInfoData: "debtorCardInfo/load",
      getSummaryReport: "debtorCardInfo/getSummaryReport",
    }),
    ...mapActions({
      loadDebtsPaymentsData: "debtorCardDebtsAndPaymentsData/load",
      loadPenaltiesData: "debtorCardPenaltiesData/load",
    }),
    f(o) {
      return formatNumber(o, { type: "fixedPoint", precision: 2 });
    },

    tabActivated(e) {
      var tab = "tab" + e.toString();
      var component = this.$refs[tab];
      if (component != null) {
        setTimeout(() => {
          component.repaint();
        }, 100);
      }
    },
    async startLoadCard() {
      this.isLoading = true;
      await this.loadDebtorInfo();
      await this.loadDebtsAndPayments();
      await this.loadPenalties();
      this.isLoading = false;
    },

    async loadDebtorInfo() {
      try {
        await this.loadInfoData(this.id);
      } catch (error) {
        console.log(error.response);
      }
    },
    async loadDebtsAndPayments() {
      try {
        await this.loadDebtsPaymentsData(this.id);
      } catch (error) {
        console.log(error);
      }
    },
    async loadPenalties() {
      try {
        await this.loadPenaltiesData(this.id);
      } catch (error) {
        console.log(error);
      }
    },

    async startDownloadSummaryReport() {
      try {
        this.isLoading = true;
        await this.getSummaryReport();
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
ul.nav-tabs > li.nav-item > a {
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
